import Auth from '@/components/auth';
import Name from '@/components/name';
import { Authenticator, useTheme, View } from '@aws-amplify/ui-react';
import { FunctionComponent } from 'react';

const ForcePassword: FunctionComponent = () => {
  return (
    <>
      <View>
        <Name />
      </View>
      <Authenticator.ForceNewPassword.FormFields />
    </>
  );
};

const Footer: FunctionComponent = () => {
  const { tokens } = useTheme();
  return (
    <View
      color={tokens.colors.white}
      backgroundColor={tokens.colors.overlay[50]}
      fontSize={tokens.fontSizes.medium}
      marginBottom={tokens.space.medium}
    >
      UNAUTHORISED ACCESS PROHIBITED
    </View>
  );
};

const InternalPage: FunctionComponent = () => {
  const groupsKey = 'cognito:groups';
  const { tokens } = useTheme();

  return (
    <Auth
      logo={{
        color: tokens.colors.overlay[70].value,
        label: 'Internal',
      }}
      forcePassword={<ForcePassword />}
      footer={<Footer />}
      onCreateClaims={(idToken, claims) => ({
        ...claims,
        userId: idToken.email,
        groups: idToken[groupsKey]
      })}
    />
  );
};

export default InternalPage;
